<div class="container">
    <div class="row small-margin-top">
        <div class="col-md-12">
            <h2>Your Propensity Models:</h2>
            <br>
            <p *ngIf="isSuperUser && (!propensityModels || propensityModels.length == 0)">You have not yet created any propensity models.</p>
            <p *ngIf="!isSuperUser && (!propensityModels || propensityModels.length == 0)">Your team or organisation administrator has not yet created any propensity models that you have access to.</p>

            <p>Propensity models allow you to design your own personalised scoring system to help you prioritise organisations in Zint based upon one or more attributes you can filter on in the dashboard.</p>
            <p>Each attribute is assigned a score by you and, the higher the score, the better fit an organisation is.</p>
        </div>
    </div>
</div>
<div class="container-fluid" style="padding-left: 5%; padding-right: 5%">
    <div class="row">
    <button class="rounded-btn-base rounded-btn-light btn-p-sm mr-3 ml-auto" (click)="toggleAccordion()"><span>
        @if (isAllExpansionPanelsOpen) {
        <i class="fal fa-arrows-to-line mr-2"></i>
        Collapse All
        }
        @else {
        <i class="fal fa-arrows-from-line mr-2"></i>
        Expand All
        }
    </span>
    </button>
    </div>
    <div class="row">
        <mat-accordion multi class="d-flex flex-column my-4 col-md-12">
                @for (propensityModel of propensityModels; track $index) { 
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="row">
                                <div class="col-md-6">
                                    <h4 *ngIf="!propensityModel.isEditingPropensityModelName" class="d-inline mr-2 text-grey-550"><i
                                            class="fal fa-star"></i>
                                        <span> {{ propensityModel.name }}</span>
                                    </h4>
                                    <i class="fal fa-edit fa-lg cursor-pointer vertical-baseline"
                                        *ngIf="!propensityModel.isEditingPropensityModelName && propensityModel.can_edit && isSuperUser"
                                        (click)="propensityModel.isEditingPropensityModelName = true;$event.stopPropagation()"></i>
                                    <div class="input-group"
                                        *ngIf="propensityModel.isEditingPropensityModelName && propensityModel.can_edit && isSuperUser">
                                        <input type="text" (keydown)="stopSomeKeyboardEvents($event)" (click)="$event.stopPropagation()"
                                            [(ngModel)]="propensityModel.name" class="form-control"
                                            [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                        <div class="input-group-append">
                                            <button class="rounded-btn-base rounded-btn-dark-hover mx-1" type="button"
                                                (click)="updatePropensityModelName(propensityModel, propensityModel.name); $event.stopPropagation()"
                                                [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                                <i class="fal fa-save" *ngIf="!isLoadingZintPropensityScore.loadingIsModifyingModel"></i> <i
                                                    class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i>
                                            </button>
                                            <button class="rounded-btn-base rounded-btn-dark-hover" type="button"
                                                (click)="propensityModel.isEditingPropensityModelName = false;$event.stopPropagation()">
                                                <i class="fal fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs float-right" *ngIf="propensityModel.can_edit && isSuperUser"
                                        (click)="confirmDeleteModel($event, propensityModel)" style="margin-top: 5px">
                                        <i class="fal fa-trash"></i> Delete Model
                                    </button>
                                    <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs float-right" *ngIf="propensityModel.can_edit && isSuperUser"
                                        (click)="confirmDuplicateModel($event, propensityModel)" style="margin-right: 5px; margin-top: 5px">
                                        <i class="fal fa-copy"></i> Duplicate Model
                                    </button>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="card">
                            <div class="card-body">

                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body overflow-scroll">
                                                Total Addressable Market: &nbsp;&nbsp;<search-parameters [inputParams]="{'searchTags': propensityModel.total_addressable_market_params, 'enableClearing': false}"></search-parameters>
                                                <a *ngIf="isSuperUser" class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" [attr.href]="'/dashboard' + propensityModel.getParams + '&editPropensityModelTAM=true'" target="_blank"><i class="fal fa-edit"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row small-margin-top">
                                    <div *ngIf="isSuperUser" class="col-md-6">
                                        <div class="card">
                                            <div class="card-body">
                                                <p>Individual View Access:</p>
                                                <table class="table table-sm table-bordered" style="width: auto">
                                                    <tr *ngFor="let viewableUser of propensityModel.view_users">
                                                        <td>{{ viewableUser.email }}</td>
                                                        <td *ngIf="propensityModel.view_users">
                                                            <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" style="font-size: 10px" (click)="viewableUserToDelete = viewableUser; propensityModelToDeleteOn = propensityModel;" data-toggle="modal" data-target="#confirmDeleteViewableUserOnPropensityModelModal"><i class="fal fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <select class="custom-select" (change)="addNewViewableUserToPropensityModel($event.target.value, propensityModel)" *ngIf="propensityModel.isAddingViewableUser">
                                                    <ng-container *ngIf="propensityModel.view_users">
                                                        <option selected></option>
                                                        <ng-container *ngFor="let organisationUser of organisationUsers;">
                                                            <option [attr.value]="organisationUser.userId" *ngIf="organisationUser | filterLoopPipe:propensityModel.view_users:'userId'">{{organisationUser.email}}</option>
                                                        </ng-container>
                                                    </ng-container>
                                                </select>
                                                <ng-container *ngIf="!propensityModel.isAddingViewableUser && organisationUsers && propensityModel.view_users && organisationUsers.length != propensityModel.view_users.length">
                                                    <button class="rounded-btn-base rounded-btn-dark-hover btn-p-sm" (click)="propensityModel.isAddingViewableUser = true">
                                                        <i class="fal fa-plus"></i> Add User
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperUser" class="col-md-6">
                                        <div class="card">
                                            <div class="card-body">
                                                <p>Team View Access:</p>
                                                <table class="table table-sm table-bordered" style="width: auto">
                                                    <tr *ngFor="let viewableTeam of propensityModel.view_teams">
                                                        <td>{{ viewableTeam.name }}</td>
                                                        <td *ngIf="propensityModel.view_teams">
                                                            <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" style="font-size: 10px" (click)="viewableTeamToDelete = viewableTeam; propensityModelToDeleteOn = propensityModel;" data-toggle="modal" data-target="#confirmDeleteViewableTeamOnPropensityModelModal"><i class="fal fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <select class="custom-select" (change)="addNewViewableTeamToPropensityModel($event.target.value, propensityModel)" *ngIf="propensityModel.isAddingViewableTeam">
                                                    <ng-container *ngIf="propensityModel.view_teams">
                                                        <option selected></option>
                                                        <ng-container *ngFor="let organisationTeam of organisationTeams;">
                                                            <option [attr.value]="organisationTeam.id" *ngIf="organisationTeam | filterLoopPipe:propensityModel.view_teams:'id'">{{organisationTeam.name}}</option>
                                                        </ng-container>
                                                    </ng-container>
                                                </select>
                                                <ng-container *ngIf="!propensityModel.isAddingViewableTeam && organisationTeams && propensityModel.view_teams && organisationTeams.length != propensityModel.view_teams.length">
                                                    <button class="rounded-btn-base rounded-btn-dark-hover btn-p-sm" (click)="propensityModel.isAddingViewableTeam = true">
                                                        <i class="fal fa-plus"></i> Add Team
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperUser" class="col-md-6 small-margin-top">
                                        <div class="card">
                                            <div class="card-body">
                                                <p>Individual Edit Access:</p>
                                                <table class="table table-sm table-bordered" style="width: auto">
                                                    <tr *ngFor="let editableUser of propensityModel.edit_users">
                                                        <td>{{ editableUser.email }}</td>
                                                        <td *ngIf="propensityModel.edit_users">
                                                            <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" style="font-size: 10px" (click)="editableUserToDelete = editableUser; propensityModelToDeleteOn = propensityModel;" data-toggle="modal" data-target="#confirmDeleteEditableUserOnPropensityModelModal"><i class="fal fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <select class="custom-select" (change)="addNewEditableUserToPropensityModel($event.target.value, propensityModel)" *ngIf="propensityModel.isAddingEditableUser">
                                                    <ng-container *ngIf="propensityModel.edit_users">
                                                        <option selected></option>
                                                        <ng-container *ngFor="let organisationUser of organisationUsers;">
                                                            <option [attr.value]="organisationUser.userId" *ngIf="organisationUser | filterLoopPipe:propensityModel.edit_users:'userId'">{{organisationUser.email}}</option>
                                                        </ng-container>
                                                    </ng-container>
                                                </select>
                                                <ng-container *ngIf="!propensityModel.isAddingEditableUser && organisationUsers && propensityModel.edit_users && organisationUsers.length != propensityModel.edit_users.length">
                                                    <button class="rounded-btn-base rounded-btn-dark-hover btn-p-sm" (click)="propensityModel.isAddingEditableUser = true">
                                                        <i class="fal fa-plus"></i> Add User
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperUser" class="col-md-6 small-margin-top">
                                        <div class="card">
                                            <div class="card-body">
                                                <p>Team Edit Access:</p>
                                                <table class="table table-sm table-bordered" style="width: auto">
                                                    <tr *ngFor="let editableTeam of propensityModel.edit_teams">
                                                        <td>{{ editableTeam.name }}</td>
                                                        <td *ngIf="propensityModel.edit_teams">
                                                            <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" style="font-size: 10px" (click)="editableTeamToDelete = editableTeam; propensityModelToDeleteOn = propensityModel;" data-toggle="modal" data-target="#confirmDeleteEditableTeamOnPropensityModelModal"><i class="fal fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <select class="custom-select" (change)="addNewEditableTeamToPropensityModel($event.target.value, propensityModel)" *ngIf="propensityModel.isAddingEditableTeam">
                                                    <ng-container *ngIf="propensityModel.edit_teams">
                                                        <option selected></option>
                                                        <ng-container *ngFor="let organisationTeam of organisationTeams;">
                                                            <option [attr.value]="organisationTeam.id" *ngIf="organisationTeam | filterLoopPipe:propensityModel.edit_teams:'id'">{{organisationTeam.name}}</option>
                                                        </ng-container>
                                                    </ng-container>
                                                </select>
                                                <ng-container *ngIf="!propensityModel.isAddingEditableTeam && organisationTeams && propensityModel.edit_teams && organisationTeams.length != propensityModel.edit_teams.length">
                                                    <button class="rounded-btn-base rounded-btn-dark-hover btn-p-sm" (click)="propensityModel.isAddingEditableTeam = true">
                                                        <i class="fal fa-plus"></i> Add Team
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <table class="table table-striped table-bordered small-margin-top" style="table-layout:fixed;">
                                    <thead>
                                        <tr>
                                            <th style="width: 200px;">Scoring Component Name</th>
                                            <th style="width: 100px;">Weighting</th>
                                            <th>Annotation</th>
                                            <th style="width: 55%;">Search Parameters</th>
                                            <th *ngIf="propensityModel.can_edit" style="width: 75px;">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="!propensityModel.scoring_components || propensityModel.scoring_components.length == 0">
                                            <td [attr.colspan]="propensityModel.edit_users ? '4' : '3'">You have not yet added any scoring components to this model.</td>
                                        </tr>
                                        <tr *ngFor="let organisationScoreComponent of propensityModel.scoring_components">
                                            <td>
                                                <span *ngIf="!organisationScoreComponent.isEditingComponentName">{{ organisationScoreComponent.name }}</span>
                                                <i class="fal fa-edit cursor-pointer float-right" *ngIf="!organisationScoreComponent.isEditingComponentName && propensityModel.can_edit" (click)="organisationScoreComponent.isEditingComponentName = true;"></i>
                                                <div class="input-group" *ngIf="organisationScoreComponent.isEditingComponentName">
                                                    <input type="text" (keyup.enter)="updatePropensityScoreComponentName(propensityModel, organisationScoreComponent, organisationScoreComponent.name)" [(ngModel)]="organisationScoreComponent.name" class="form-control" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                                    <div class="input-group-append">
                                                        <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs mx-1" type="button" (click)="updatePropensityScoreComponentName(propensityModel, organisationScoreComponent, organisationScoreComponent.name)" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                                            <i class="fal fa-save" *ngIf="!isLoadingZintPropensityScore.loadingIsModifyingModel"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i>
                                                        </button>
                                                        <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" type="button" (click)="organisationScoreComponent.isEditingComponentName = false">
                                                            <i class="fal fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span *ngIf="!organisationScoreComponent.isEditingWeighting">{{ organisationScoreComponent.weighting }}</span>
                                                <i class="fal fa-edit cursor-pointer float-right" *ngIf="!organisationScoreComponent.isEditingWeighting && propensityModel.can_edit" (click)="organisationScoreComponent.isEditingWeighting = true;"></i>
                                                <div class="input-group" *ngIf="organisationScoreComponent.isEditingWeighting">
                                                    <input type="number" (keyup.enter)="updatePropensityScoreComponentWeightingOnPropensityModel(propensityModel, organisationScoreComponent, organisationScoreComponent.weighting)" (keyup)="validatePropensityScoreWeightingValue(organisationScoreComponent)" [(ngModel)]="organisationScoreComponent.weighting" style="width: 50px;" class="form-control" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                                    <div class="input-group-append">
                                                        <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs mr-1" type="button" (click)="updatePropensityScoreComponentWeightingOnPropensityModel(propensityModel, organisationScoreComponent, organisationScoreComponent.weighting)" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                                            <i class="fal fa-save" *ngIf="!isLoadingZintPropensityScore.loadingIsModifyingModel"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i>
                                                        </button>
                                                        <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" type="button" (click)="organisationScoreComponent.isEditingWeighting = false">
                                                            <i class="fal fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span *ngIf="!organisationScoreComponent.isEditingAnnotation">{{ organisationScoreComponent.annotation }}</span>
                                                <i class="fal fa-edit cursor-pointer float-right" *ngIf="!organisationScoreComponent.isEditingAnnotation && propensityModel.can_edit" (click)="organisationScoreComponent.isEditingAnnotation = true;"></i>
                                                <div class="input-group" *ngIf="organisationScoreComponent.isEditingAnnotation">
                                                    <input type="text" (keyup.enter)="updatePropensityScoreComponentAnnotationOnPropensityModel(propensityModel, organisationScoreComponent, organisationScoreComponent.annotation)" [(ngModel)]="organisationScoreComponent.annotation" style="width: 50px;" class="form-control" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                                    <div class="input-group-append">
                                                        <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs mr-1" type="button" (click)="updatePropensityScoreComponentAnnotationOnPropensityModel(propensityModel, organisationScoreComponent, organisationScoreComponent.annotation)" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                                                            <i class="fal fa-save" *ngIf="!isLoadingZintPropensityScore.loadingIsModifyingModel"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i>
                                                        </button>
                                                        <button class="rounded-btn-base rounded-btn-dark-hover btn-p-xs" type="button" (click)="organisationScoreComponent.isEditingAnnotation = false">
                                                            <i class="fal fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="overflow-scroll">
                                                <search-parameters [inputParams]="{'searchTags': organisationScoreComponent.params, 'enableClearing': false}"></search-parameters>
                                            </td>
                                            <td *ngIf="propensityModel.can_edit">
                                                <button class="rounded-btn-base rounded-btn-dark-hover" (click)="organisationPropensityScoreComponentToDelete = organisationScoreComponent; propensityModelToDeleteOn = propensityModel;" data-toggle="modal" data-target="#confirmDeleteOrganisationPropensityModelScoringComponentModal"><i class="fal fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </mat-expansion-panel>
                }
        </mat-accordion>
    </div>
</div>





<div class="modal fade" id="confirmDeleteOrganisationPropensityModelScoringComponentModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteOrganisationPropensityModelScoringComponentModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="organisationPropensityScoreComponentToDelete && propensityModelToDeleteOn">
                <p>This will permanently delete the propensity score component named "{{ organisationPropensityScoreComponentToDelete.name }}" from your model "{{ propensityModelToDeleteOn.name }}".</p>
                <p>Please be patient while this is deleted as your propensity scores will be updated in real-time.</p>
                <button class="rounded-btn-base rounded-btn-red btn-p-sm" (click)="deletePropensityScoreComponent()" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i> Confirm
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDeleteViewableUserOnPropensityModelModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteViewableUserOnPropensityModelModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="viewableUserToDelete && propensityModelToDeleteOn">
                <p>This will remove editing and viewing access for {{ viewableUserToDelete.email }} from the model {{ propensityModelToDeleteOn.name }}.</p>
                <button class="rounded-btn-base rounded-btn-red btn-p-sm" (click)="deleteViewingAccessForUserOnPropensityModel()" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i> Confirm
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDeleteViewableTeamOnPropensityModelModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteViewableTeamOnPropensityModelModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="viewableTeamToDelete && propensityModelToDeleteOn">
                <p>This will remove editing and viewing access for {{ viewableTeamToDelete.name }} from the model {{ propensityModelToDeleteOn.name }}.</p>
                <button class="rounded-btn-base rounded-btn-red btn-p-sm" (click)="deleteViewingAccessForTeamOnPropensityModel()" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i> Confirm
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDeleteEditableUserOnPropensityModelModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteEditableUserOnPropensityModelModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="editableUserToDelete && propensityModelToDeleteOn">
                <p>This will remove editing access for {{ editableUserToDelete.email }} from the model {{ propensityModelToDeleteOn.name }}. Please note that viewing access will need to be removed separately.</p>
                <button class="rounded-btn-base rounded-btn-red btn-p-sm" (click)="deleteEditingAccessForUserOnPropensityModel()" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i> Confirm
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDeleteEditableTeamOnPropensityModelModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteEditableTeamOnPropensityModelModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="editableTeamToDelete && propensityModelToDeleteOn">
                <p>This will remove editing access for {{ editableTeamToDelete.name }} from the model {{ propensityModelToDeleteOn.name }}. Please note that viewing access will need to be removed separately.</p>
                <button class="rounded-btn-base rounded-btn-red btn-p-sm" (click)="deleteEditingAccessForTeamOnPropensityModel()" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i> Confirm
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDeletePropensityModelModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeletePropensityModelModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="propensityModelToDelete">
                <p>WARNING: This will irreversibly delete the propensity model "{{ propensityModelToDelete.name }}", all associated propensity score components and calculated propensity scores.</p>
                <button class="rounded-btn-base rounded-btn-red btn-p-md" (click)="deletePropensityModel()" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i> Confirm
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDuplicatePropensityModelModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeletePropensityModelModalLabel">Confirm Duplicate</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="propensityModelToDuplicate">
                <p>This will create a copy of the propensity model "{{ propensityModelToDuplicate.name }}" with the same access levels and scoring components.</p>
                <button class="rounded-btn-base rounded-btn-orange btn-p-sm" (click)="duplicatePropensityModel()" [attr.disabled]="isLoadingZintPropensityScore.loadingIsModifyingModel ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoadingZintPropensityScore.loadingIsModifyingModel"></i> Confirm
                </button>
            </div>
        </div>
    </div>
</div>

