import { Component, Input } from '@angular/core';

@Component({
  selector: 'truncate-text',
  templateUrl: './truncate-text.component.html',
  styleUrls: ['./truncate-text.component.css'],
})
export class TruncateTextComponent {
  @Input() inputText: string;
  @Input() limit: number = 20;
  @Input() block: boolean = true;
  @Input() textColor: string = 'var(--zint-grey-600)';
  @Input() showEllipsis = false;

  isExpanded: boolean = false;

  get displayText(): string {
    if (this.isExpanded || !this.inputText) {
      return this.inputText;
    }

    const words = this.inputText.split(' ');
    if (words.length <= this.limit) {
      return this.inputText;
    }

    return (
      words.slice(0, this.limit).join(' ') + `${this.showEllipsis ? '' : '...'}`
    );
  }

  toggleText(): void {
    this.isExpanded = !this.isExpanded;
  }
}
