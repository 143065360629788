import { Component, OnInit, ViewChild } from '@angular/core';
import { ZintPropensityScoreService } from '../../services/zint-propensity-score.service';
import { CommunicationService } from '../../services/communication.service';
import { publicMethods } from '../../globals';
import { MatAccordion } from '@angular/material/expansion';
declare var $: any;

@Component({
  selector: 'propensity-score',
  templateUrl: './propensity-score.component.html',
  styleUrls: ['./propensity-score.component.css'],
})
export class PropensityScoreComponent implements OnInit {
  organisationUsers: Array<any> = [];
  organisationTeams: Array<any> = [];
  propensityModels: Array<any> = [];
  propensityWeightingRange: Array<any> = [];
  organisationPropensityScoreComponentToDelete: any;
  propensityModelToDelete: any;
  propensityModelToDeleteOn: any;
  propensityModelToDuplicate: any;
  viewableUserToDelete: any;
  viewableTeamToDelete: any;
  editableUserToDelete: any;
  editableTeamToDelete: any;

  isSuperUser: boolean = false;
  isAllExpansionPanelsOpen: boolean = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    private zintPropensityScoreService: ZintPropensityScoreService,
    private communicationService: CommunicationService,
    private pubMethods: publicMethods
  ) {}

  isLoadingZintPropensityScore = this.zintPropensityScoreService.isLoading;

  ngOnInit() {
    document.title = 'Propensity';
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.zintPropensityScoreService
      .getUserPropensityScoreDetails(true)
      .subscribe(data => this.updateZintPropensityScoreDetails(data));
  }

  toggleAccordion(): void {
    if (this.isAllExpansionPanelsOpen) {
      this.accordion.closeAll();
      this.isAllExpansionPanelsOpen = false;
      return;
    }
    this.accordion.openAll();
    this.isAllExpansionPanelsOpen = true;
  }

  updateZintPropensityScoreDetails(data): void {
    this.propensityModels = data.propensity_models;
    this.propensityModels?.forEach(model => {
      model['getParams'] = this.pubMethods.getGetSearchStringFromParams(
        model['total_addressable_market_params']
      );
      model['total_addressable_market_params'] =
        this.pubMethods.changeSearchParamsToDisplayFormat(
          model['total_addressable_market_params']
        );
    });
    this.propensityModels?.forEach(model => {
      model['scoring_components'].forEach(scoring_components => {
        scoring_components['params'] =
          this.pubMethods.changeSearchParamsToDisplayFormat(
            scoring_components['params']
          );
      });
    });
    this.propensityWeightingRange = data.propensity_weighting_range;
    if (data.organisation_users) {
      this.organisationUsers = data.organisation_users;
    }
    if (data.organisation_teams) {
      this.organisationTeams = data.organisation_teams;
    }
  }

  stopSomeKeyboardEvents(event: KeyboardEvent): void {
    const keysThatTriggerPanel = [' ', 'Enter']; // space and enter
    if (keysThatTriggerPanel.includes(event.key)) {
      event.stopPropagation();
    }
  }

  // ------ Duplicating Model ------ //
  confirmDuplicateModel(event: MouseEvent, propensityModel: any): void {
    event.stopPropagation();
    event.preventDefault();
    $('#confirmDuplicatePropensityModelModal').modal('show');
    this.propensityModelToDuplicate = propensityModel;
  }

  duplicatePropensityModel(): void {
    this.zintPropensityScoreService
      .duplicatePropensityModel(this.propensityModelToDuplicate.id)
      .subscribe(data => this.processPropensityModelDuplicated(data));
  }

  processPropensityModelDuplicated(data) {
    this.updateZintPropensityScoreDetails(data);
    $('#confirmDuplicatePropensityModelModal').modal('hide');
    this.pubMethods.showInfoMessage(
      'Propensity Model successfully duplicated. Scores will be updated in the next 1-2 hours.'
    );
  }
  // ------ Duplicating Model ------ //

  // ------ Deleting Model ------ //
  confirmDeleteModel(event: MouseEvent, propensityModel: any): void {
    event.stopPropagation();
    event.preventDefault();
    $('#confirmDeletePropensityModelModal').modal('show');
    this.propensityModelToDelete = propensityModel;
  }

  deletePropensityModel(): void {
    this.zintPropensityScoreService
      .deletePropensityModel(this.propensityModelToDelete.id)
      .subscribe(data => this.processDeletedPropensityScoreModel(data));
  }

  processDeletedPropensityScoreModel(data) {
    this.updateZintPropensityScoreDetails(data);
    $('#confirmDeletePropensityModelModal').modal('hide');
    this.pubMethods.showInfoMessage('This propensity model was deleted.');
  }
  // ------ Deleting Model ------ //

  // ------ Deleting Component ------ //
  deletePropensityScoreComponent(): void {
    this.zintPropensityScoreService
      .deletePropensityScoreComponent(
        this.organisationPropensityScoreComponentToDelete.id,
        this.propensityModelToDeleteOn.id
      )
      .subscribe(data =>
        this.processDeletedOrganisationPropensityScoreComponent(data)
      );
  }

  processDeletedOrganisationPropensityScoreComponent(data) {
    this.updateZintPropensityScoreDetails(data);
    $('#confirmDeleteOrganisationPropensityModelScoringComponentModal').modal(
      'hide'
    );
    this.pubMethods.showInfoMessage(
      'This component was deleted and propensity scores will be updated.'
    );
  }
  // ------ Deleting Component ------ //

  updateAfterSuccessAddingUser(propensityModel, data): void {
    propensityModel.isAddingViewableUser = false;
    propensityModel.isAddingViewableTeam = false;
    propensityModel.isAddingEditableUser = false;
    propensityModel.isAddingEditableTeam = false;
    this.updateZintPropensityScoreDetails(data);
  }

  addNewViewableUserToPropensityModel(userId, propensityModel): void {
    this.zintPropensityScoreService
      .addViewingAccessForUserOnPropensityModel(propensityModel.id, userId)
      .subscribe(data =>
        this.updateAfterSuccessAddingUser(propensityModel, data)
      );
  }

  addNewViewableTeamToPropensityModel(orgId, propensityModel): void {
    this.zintPropensityScoreService
      .addViewingAccessForTeamOnPropensityModel(propensityModel.id, orgId)
      .subscribe(data =>
        this.updateAfterSuccessAddingUser(propensityModel, data)
      );
  }

  addNewEditableUserToPropensityModel(userId, propensityModel): void {
    this.zintPropensityScoreService
      .addEditingAccessForUserOnPropensityModel(propensityModel.id, userId)
      .subscribe(data =>
        this.updateAfterSuccessAddingUser(propensityModel, data)
      );
  }

  addNewEditableTeamToPropensityModel(orgId, propensityModel): void {
    this.zintPropensityScoreService
      .addEditingAccessForTeamOnPropensityModel(propensityModel.id, orgId)
      .subscribe(data =>
        this.updateAfterSuccessAddingUser(propensityModel, data)
      );
  }

  processUserAccessDeleted(data): void {
    this.updateZintPropensityScoreDetails(data);
    $('#confirmDeleteViewableUserOnPropensityModelModal').modal('hide');
    $('#confirmDeleteViewableTeamOnPropensityModelModal').modal('hide');
    $('#confirmDeleteEditableUserOnPropensityModelModal').modal('hide');
    $('#confirmDeleteEditableTeamOnPropensityModelModal').modal('hide');
    this.pubMethods.showInfoMessage('Access for this user was removed.');
  }

  deleteViewingAccessForUserOnPropensityModel(): void {
    this.zintPropensityScoreService
      .deleteViewingAccessForUserOnPropensityModel(
        this.propensityModelToDeleteOn.id,
        this.viewableUserToDelete.userId
      )
      .subscribe(data => this.processUserAccessDeleted(data));
  }

  deleteViewingAccessForTeamOnPropensityModel(): void {
    this.zintPropensityScoreService
      .deleteViewingAccessForTeamOnPropensityModel(
        this.propensityModelToDeleteOn.id,
        this.viewableTeamToDelete.id
      )
      .subscribe(data => this.processUserAccessDeleted(data));
  }

  deleteEditingAccessForUserOnPropensityModel(): void {
    this.zintPropensityScoreService
      .deleteEditingAccessForUserOnPropensityModel(
        this.propensityModelToDeleteOn.id,
        this.editableUserToDelete.userId
      )
      .subscribe(data => this.processUserAccessDeleted(data));
  }

  deleteEditingAccessForTeamOnPropensityModel(): void {
    this.zintPropensityScoreService
      .deleteEditingAccessForTeamOnPropensityModel(
        this.propensityModelToDeleteOn.id,
        this.editableTeamToDelete.id
      )
      .subscribe(data => this.processUserAccessDeleted(data));
  }

  updatePropensityScoreComponentWeightingOnPropensityModel(
    propensityModel,
    organisationPropensityScoreComponent,
    weighting
  ): void {
    this.zintPropensityScoreService
      .updatePropensityScoreComponentWeighting(
        propensityModel.id,
        organisationPropensityScoreComponent.id,
        weighting
      )
      .subscribe(data => this.updateZintPropensityScoreDetails(data));
  }

  updatePropensityScoreComponentAnnotationOnPropensityModel(
    propensityModel,
    organisationPropensityScoreComponent,
    annotation
  ): void {
    this.zintPropensityScoreService
      .updatePropensityScoreComponentAnnotation(
        propensityModel.id,
        organisationPropensityScoreComponent.id,
        annotation
      )
      .subscribe(data => this.updateZintPropensityScoreDetails(data));
  }

  updatePropensityScoreComponentName(
    propensityModel,
    organisationPropensityScoreComponent,
    name
  ): void {
    this.zintPropensityScoreService
      .updatePropensityScoreComponentName(
        propensityModel.id,
        organisationPropensityScoreComponent.id,
        name
      )
      .subscribe(data => this.updateZintPropensityScoreDetails(data));
  }

  updatePropensityModelName(propensityModel, name): void {
    this.zintPropensityScoreService
      .updatePropensityModelName(propensityModel.id, name)
      .subscribe(data => this.updateZintPropensityScoreDetails(data));
  }

  validatePropensityScoreWeightingValue(organisationScoreComponent): void {
    if (
      organisationScoreComponent.weighting &&
      this.propensityWeightingRange.length == 2
    ) {
      if (
        organisationScoreComponent.weighting < this.propensityWeightingRange[0]
      ) {
        organisationScoreComponent.weighting = this.propensityWeightingRange[0];
      }
      if (
        organisationScoreComponent.weighting > this.propensityWeightingRange[1]
      ) {
        organisationScoreComponent.weighting = this.propensityWeightingRange[1];
      }
    }
  }
}
